<template>
  <div>
    <div>
      <head-layout :head-btn-options="headBtnOptions"
      :head-title="$t('cip.plat.sys.menu.title.indexHeadTitle')"
      @head-add="getNew" @head-romve="headRomve">
      </head-layout>
      <grid-head-layout ref="searchFrom" :search-columns="searchColumns" v-model="searchForm"
        @grid-head-search="searchChange" @grid-head-empty="searchReset">
      </grid-head-layout>
      <grid-layout ref="gridLayOut" :tableOptions="option" :tableData="data" :table-loading="loading"
        @gird-handle-select-click="selectionChange" :gridRowBtn="gridRowBtn" @grid-edit="rowUpdate" @grid-romve="rowDel"
        @row-update="addUpdate" @tree-load='treeLoad' @grid-addChild="handleAdd"
       :page="page" :data-total="page.total"
       @page-current-change="onLoad"
       @page-size-change="onLoad"
       @page-refresh-change="onLoad"
      >

      </grid-layout>
      <el-drawer class="el-drawer__wrapper avue-dialog avue-crud__dialog" :visible.sync="isShow" size="70%"
        style="margin-top: 50px;" :show-close="false">
        <template slot="title">
          <dialog-head-btn @dialog-head-save-click="save" @dialog-head-cancel-click="cancel" head-title="" icon="">
          </dialog-head-btn>
        </template>
        <form-layout v-if="isShow" :column="option.column" :dataForm="dataObj" ref="formLayout"></form-layout>
      </el-drawer>
    </div>

    <!-- <basic-container>
      <avue-crud :option="option" :table-loading="loading" :data="data" ref="crud" v-model="form"
        :permission="permissionList" :before-open="beforeOpen" :before-close="beforeClose" @row-del="rowDel"
        @row-update="rowUpdate" @row-save="rowSave" @search-change="searchChange" @search-reset="searchReset"
        @selection-change="selectionChange" @current-change="currentChange" @size-change="sizeChange"
        @refresh-change="refreshChange" @on-load="onLoad" @tree-load="treeLoad">
        <template slot="menuLeft">
          <el-button type="danger" size="small" icon="el-icon-delete" v-if="permission.menu_delete" plain
            @click="handleDelete">删 除
          </el-button>
        </template>
        <template slot-scope="scope" slot="menu">
          <el-button type="text" icon="el-icon-circle-plus-outline" size="small"
            @click.stop="handleAdd(scope.row, scope.index)"
            v-if="userInfo.role_name.includes('administrator') && scope.row.category === 1">新增子项
          </el-button>
        </template>
        <template slot-scope="{row}" slot="source">
          <div style="text-align:center">
            <i :class="row.source" />
          </div>
        </template>
      </avue-crud>
    </basic-container> -->
  </div>
</template>

<script>
import {PAGE_CONSTANT} from "@/util/pageConstantEnum";
import { getLazyList, remove, update, add, getMenu } from "@/api/system/menu";
import { mapGetters } from "vuex";
import iconList from "@/config/iconList";
import func from "@/util/func";
import { getMenuTree } from "@/api/system/menu";
import HeadLayout from "@/views/components/layout/head-layout"
import GridLayout from "@/views/components/layout/grid-layout";
import dialogHeadBtn from "@/views/components/layout/dialog-head-btn";
import formLayout from '@/views/components/layout/form-layout'
export default {
  components: {
    HeadLayout,
    GridLayout,
    dialogHeadBtn,
    formLayout
  },
  data() {
    return {
      isShow: false,
      dataObj: '',
      searchColumns: [
        {
          label: "",
          prop: "name",
          search: true,
          placeholder:this.$t("cip.cmn.rule.inputWarning")+this.$t("cip.plat.sys.menu.field.name"),
        },
        {
          label: "",
          prop: "code",
          search: true,
         placeholder:this.$t("cip.cmn.rule.inputWarning")+this.$t("cip.plat.sys.menu.field.code"),
        },
        {
          label: "",
          placeholder:this.$t("cip.cmn.rule.inputWarning")+this.$t("cip.plat.sys.menu.field.alias"),
          prop: "alias",
          search: true,
        },
      ],
      form: {},
      query: {},
      loading: true,
      selectionList: [],
      parentId: 0,
      page: {
        pageSize: 1000,
        pageSizes: [10, 20,30, 40, 50, 100,1000],
        currentPage: 1,
        total: 0,
      },
      option: {
        lazy: true,
        tip: false,
        simplePage: true,
        searchShow: false,
        searchMenuSpan: 6,
        dialogWidth: "60%",
        tree: true,
        border: true,
        index: false,
        selection: true,
        viewBtn: false,
        menuWidth: 300,
        dialogClickModal: false,
        column: [
          {
            label: this.$t("cip.plat.sys.menu.field.name"),
            prop: "name",
            search: true,
            align: "left",
            width: 240,
            overHidden: true,
            rules: [
              {
                required: true,
                message: this.$t("cip.cmn.rule.inputWarning")+this.$t("cip.plat.sys.menu.field.name"),
                trigger: "blur"
              }
            ]
          },
          {
            label:this.$t("cip.plat.sys.menu.field.path"),
            prop: "path",
            align: "left",
            overHidden: true,
            rules: [
              {
                required: true,
               message: this.$t("cip.cmn.rule.inputWarning")+this.$t("cip.plat.sys.menu.field.path"),
                trigger: "blur"
              }
            ]
          },
          {
            label: this.$t("cip.plat.sys.menu.field.parentId"),
            prop: "parentId",
            type: "tree",
            dicData: [],
            hide: true,
            addDisabled: false,
            align: "left",
            width: 100,
            overHidden: true,
            props: {
              label: "title"
            },
            rules: [
              {
                required: false,
                message: this.$t("cip.cmn.rule.inputWarning")+this.$t("cip.plat.sys.menu.field.parentId"),
                trigger: "click"
              }
            ]
          },
          {
            label: this.$t("cip.plat.sys.menu.field.source"),
            prop: "source",
            type: "icon",
            slot: true,
            iconList: iconList,
            hide: true,
            align: "left",
            width: 100,
            overHidden: true,
            rules: [
              {
                required: true,
                message: this.$t("cip.cmn.rule.selectWarning")+this.$t("cip.plat.sys.menu.field.source"),
                trigger: "click"
              }
            ]
          },
          {
            label: this.$t("cip.plat.sys.menu.field.platform"),
            type: "select",
            dicUrl: "/api/sinoma-system/dict/dictionary?code=user_type",
            hide: true,
            props: {
              label: "dictValue",
              value: "dictKey"
            },
            width: 100,
            overHidden: true,
            prop: "platform",
          },
          {
            label: this.$t("cip.plat.sys.menu.field.code"),
            prop: "code",
            search: true,
            width: 240,
            align: "left",
            overHidden: true,
            rules: [
              {
                required: true,
                message: this.$t("cip.cmn.rule.selectWarning")+this.$t("cip.plat.sys.menu.field.code"),
                trigger: "blur"
              }
            ]
          },
          {
            label: this.$t("cip.plat.sys.menu.field.category"),
            prop: "category",
            type: "radio",
            width: 240,
            overHidden: true,
            // dicData: [
            //   {
            //     label: this.$t("cip.plat.sys.menu.field.menus"),
            //     value: 1
            //   },
            //   {
            //     label: this.$t("cip.plat.sys.menu.field.button"),
            //     value: 2
            //   }
            // ],
            dataType: 'number',
            props: {
              label: "dictValue",
              value: "dictKey"
            },
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=catelog_menus_button",
            hide: true,
            rules: [
              {
                required: true,
                message: this.$t("cip.cmn.rule.inputWarning")+this.$t("cip.plat.sys.menu.field.category"),
                trigger: "blur"
              }
            ]
          },
          {
            label:this.$t("cip.plat.sys.menu.field.alias"),
            prop: "alias",
            search: true,
            align: "left",
            width: 240,
            overHidden: true,
            rules: [
              {
                required: true,
               message: this.$t("cip.cmn.rule.selectWarning")+this.$t("cip.plat.sys.menu.field.alias"),
                trigger: "blur"
              }
            ]
          },
          {
            label: this.$t("cip.plat.sys.menu.field.isOpen"),
            prop: "isOpen",
            type: "radio",
            disabled: false,
            hide: true,
            width: 100,
            overHidden: true,
            // dicData: [
            //   {
            //     label: this.$t("cip.plat.sys.menu.field.yes"),
            //     value: 1
            //   },
            //   {
            //     label: this.$t("cip.plat.sys.menu.field.no"),
            //     value: 2
            //   }
            // ]
            dataType: 'number',
            props: {
              label: "dictValue",
              value: "dictKey"
            },
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=yesandno",
          },
          {
            label: this.$t("cip.plat.sys.menu.field.status"),
            prop: "status",
            type: "radio",
            align:'center',
            width: 100,
            overHidden: true,
            // dicData: [
            //   {
            //     label: this.$t("cip.plat.sys.menu.field.yes"),
            //     value: 0
            //   },
            //   {
            //     label: this.$t("cip.plat.sys.menu.field.no"),
            //     value: 1
            //   }
            // ],
            dataType: 'number',
            props: {
              label: "dictValue",
              value: "dictKey"
            },
            dicUrl: "/api/sinoma-system/dict-biz/dictionary?code=YESORNO",
          },
          {
            label: this.$t("cip.plat.sys.menu.field.sort"),
            prop: "sort",
            type: "number",
            minRows: 0,
            maxRows: 1000000000,
            align: 'right',
            width: 100,
            overHidden: true,
            row: true,
            rules: [
                {
                  required: true,
                  message: this.$t(`cip.cmn.rule.inputWarning`) + this.$t(`cip.plat.sys.menu.field.sort`),
                  trigger: "blur",
                },
              ],
          },
          {
            label: this.$t("cip.plat.sys.menu.field.remark"),
            prop: "remark",
            type: "textarea",
            minRows: 2,
            align: 'right',
            width: 100,
            overHidden: true,
            hide: true
          }
        ]
      },
      data: []
    };
  },
  watch: {
    'form.category'() {
      const category = func.toInt(this.form.category);
      this.$refs.crud.option.column.filter(item => {
        if (item.prop === "path") {
          item.rules[0].required = category === 1;
        }
        if (item.prop === 'isOpen') {
          item.disabled = category === 2;
        }
      });
    },
  },
  computed: {
    ...mapGetters(["userInfo", "permission"]),
    permissionList() {
      return {
        addBtn: this.vaildData(this.permission.menu_add, false),
        viewBtn: this.vaildData(this.permission.menu_view, false),
        delBtn: this.vaildData(this.permission.menu_delete, false),
        editBtn: this.vaildData(this.permission.menu_edit, false)
      };
    },
    headBtnOptions() {
      let   buttonBtn = [];
      if (this.permission.menu_add) {
        buttonBtn.push(
          {
              label: this.$t("cip.cmn.btn.addBtn"),
              emit: "head-add",
              type: "button",
              icon: ""
            });
      }
      if (this.permission.menu_delete) {
        buttonBtn.push(
          {
            label: this.$t('cip.cmn.btn.delBtn'),
            emit: "head-romve",
            type: "button",
            icon: ""
          });
      }
      return buttonBtn;
    },
    // 行按钮添加 add by steve
    gridRowBtn() {
      // gridRowBtn: [
      //   {
      //     label: this.$t("cip.cmn.btn.editBtn"),
      //     emit: "grid-edit",
      //     type: "text",
      //     icon: ""
      //   }, {
      //     label: this.$t("cip.cmn.btn.delBtn"),
      //     emit: "grid-romve",
      //     type: "text",
      //     icon: ""
      //   },
      //   {
      //     label: this.$t("cip.cmn.btn.addChildBtn"),
      //     emit: "grid-addChild",
      //     type: "text",
      //     icon: ""
      //   },
      // ],
      let   buttonBtn = [];
      if (this.permission.menu_edit) {
        buttonBtn.push(
          {
            label: this.$t('cip.cmn.btn.editBtn'),
            emit: "grid-edit",
            type: "text",
            icon: ""
          });
      }
      if (this.permission.menu_addChild) {
        buttonBtn.push(
          {
            label: this.$t('cip.cmn.btn.addChildBtn'),
            emit: "grid-addChild",
            type: "text",
            icon: ""
          })
      }

      if (this.permission.menu_delete) {
        buttonBtn.push(
          {
            label: this.$t('cip.cmn.btn.delBtn'),
            emit: "grid-romve",
            type: "text",
            icon: ""
          })
      }
      return buttonBtn;
    },
    ids() {
      let ids = [];
      this.selectionList.forEach(ele => {
        ids.push(ele.id);
      });
      return ids.join(",");
    }
  },
  methods: {
    save() {
      this.$refs.formLayout.$refs.form.validate(valid => {
        if (valid) {
          let obj = this.$refs.formLayout.dataForm
          let requestType = obj.hasOwnProperty('id') ? update : add
          requestType(obj).then(() => {
            this.parentId = 0
            this.onLoad(this.page);
            this.initData()
            this.dataObj = ''
            this.$message({
              type: "success",
              message: this.$t("cip.cmn.msg.success.operateSuccess"),
            });
            this.isShow = false
            //
          }, error => {
            window.console.log(error);
            this.$refs.formLayout.$refs.form.allDisabled=false
          });
        } else {
          this.$refs.formLayout.$refs.form.allDisabled=false
        }
      })

    },
    cancel() {
      this.isShow = false;
      this.dataObj = ''
    },
    getNew() {
      this.$router.push({
        path:'/menu/menuEdit',
        query:{
          type:'add',
         }
      })

    },
    headRomve() {
      this.handleDelete()
    },
    initData() {
      getMenuTree().then(res => {
        const column = this.findObject(this.option.column, "parentId");
        column.dicData = res.data.data;
      });
    },
    handleAdd(row) {
      this.$router.push({
        path:'/menu/menuEdit',
        query:{
          type:'addChild',
          id:row.id,
         }
      })
    },
    rowSave(row, done, loading) {
      add(row).then((res) => {
        // 获取新增数据的相关字段
        const data = res.data.data;
        row.id = data.id;
        this.$message({
          type: "success",
          message: this.$t("cip.cmn.msg.success.operateSuccess"),
        });
        // 数据回调进行刷新
        // done(row);
      }, error => {
        window.console.log(error);
        loading();
      });
    },
    rowUpdate(row, index, done, loading) {
      let data=encodeURIComponent(JSON.stringify(row))
      this.$router.push({
        path:'/menu/menuEdit',
        query:{
          type:'edit',
          id:row.id
        }
      })
    },
    rowDel(row, index, done) {
      this.$confirm(this.$t('cip.cmn.msg.warning.delWarning'), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      })
        .then(() => {
          return remove(row.id);
        })
        .then(() => {
          this.$message({
            type: "success",
            message: this.$t("cip.cmn.msg.success.operateSuccess"),
          });
          //  此处this.page 被人删了
          this.onLoad(this.page)
          // 数据回调进行刷新
          // done(row);
        });
    },
    handleDelete() {
      if (this.selectionList.length === 0) {
        this.$message.warning(this.$t('cip.cmn.msg.warning.selectWarning'));
        return;
      }
      this.$confirm(this.$t('cip.cmn.msg.warning.determineDelWarning'), {
        confirmButtonText: this.$t('cip.cmn.btn.defBtn'),
        cancelButtonText: this.$t('cip.cmn.btn.celBtn'),
        type: "warning"
      })
        .then(() => {
          return remove(this.ids);
        })
        .then(() => {
          // 刷新表格数据并重载
          this.data = [];
          this.parentId = 0;
          this.$refs.gridLayOut.selectionClear();
          // 表格数据重载
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: this.$t("cip.cmn.msg.success.operateSuccess"),
          });
        });
    },
    searchReset() {
      this.query = {};
      this.parentId = 0;
      this.onLoad(this.page);
    },
    searchChange(params, done) {
      this.query = params;
      this.parentId = '0';
      this.page.currentPage = 1;
      this.onLoad(this.page, params);
      // done();
    },
    selectionChange(list) {
      this.selectionList = list;
    },
    selectionClear() {
      this.selectionList = [];
      this.$refs.gridLayOut.selectionClear()
    },
    beforeOpen(done, type) {
      if (["add", "edit"].includes(type)) {
        this.initData();
      }
      if (["edit", "view"].includes(type)) {
        getMenu(this.form.id).then(res => {
          this.form = res.data.data;
        });
      }
      // done();
    },
    beforeClose(done) {
      this.parentId = "";
      const column = this.findObject(this.option.column, "parentId");
      column.value = "";
      column.addDisabled = false;
      // done();
    },
    currentChange(currentPage) {
      this.page.currentPage = currentPage;
    },
    sizeChange(pageSize) {
      this.page.pageSize = pageSize;
    },
    refreshChange() {
      this.onLoad(this.page, this.query);
    },
    onLoad(page, params = {}) {
      this.page = page;
      this.loading = true;

      getLazyList(this.parentId,  page.currentPage, page.pageSize,Object.assign(params, this.query)).then(res => {
        let data = res.data.data;
        this.data  = data.records;
        // this.page.total = data.total;
        this.loading = false;
        this.$refs.gridLayOut.grid.refreshTable()
        this.$refs.gridLayOut.selectionClear()
      });
    },
    treeLoad(tree, treeNode, resolve) {
      let page = {};
      page.pageSize = 1000;
      page.currentPage = 1;
      const parentId = tree.id;
      getLazyList(parentId , page.currentPage, page.pageSize,{}).then(res => {
        resolve(res.data.data.records);
      });
    }
  },
  mounted(){
    this.onLoad(this.page)
  },
  created() {
    this.initData()
  },
};
</script>

<style>
</style>
